<script setup lang="ts">
import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/vue-query';
import type { Routine } from '@/types/extended';
import { useRoutinesStore } from '@/stores/routines';
import { routinesApi } from '@/api';
import { BaseButton, BaseModal } from '.';

const props = defineProps<{
  routine: Routine;
  isOpen: boolean,
}>();

const emit = defineEmits<{
  close: [isSuccess: boolean],
}>();

const routinesStore = useRoutinesStore();

const {
  isError,
  isPending,
  isSuccess,
  error: deleteRoutineError,
  mutate: deleteRoutine,
} = useMutation({
  mutationFn: () => routinesApi.destroy(props.routine.id),
});

function handleClose() {
  if (isSuccess) {
    routinesStore.removeRoutine(props.routine.id);
  }

  emit('close', isSuccess.value);
}
</script>

<template>
  <BaseModal
    :open="props.isOpen"
    title="Eliminar rutina"
    @close="handleClose"
  >
    <div
      v-if="isSuccess"
      class="flex flex-col gap-8"
    >
      <span>
        Rutina eliminada exitosamente
      </span>
      <BaseButton @click="handleClose">
        Cerrar
      </BaseButton>
    </div>
    <div
      v-else-if="isPending"
      class="flex items-center justify-center gap-4"
    >
      <span>
        Eliminando rutina...
      </span>
    </div>
    <div
      v-else-if="isError"
      class="flex flex-col gap-8"
    >
      <span>
        Ocurrió un error al eliminar la rutina
      </span>
      <span
        v-if="(deleteRoutineError instanceof AxiosError)"
        class="whitespace-pre-wrap text-sm"
      >
        {{ deleteRoutineError.response?.data }}
      </span>
      <BaseButton
        variant="secondary"
        @click="handleClose"
      >
        Cerrar
      </BaseButton>
    </div>
    <div
      v-else
      class="flex flex-col gap-8"
    >
      <span>
        ¿Estás seguro que deseas eliminar esta rutina?
      </span>
      <div class="flex justify-end gap-4">
        <BaseButton
          variant="secondary"
          @click="handleClose"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          variant="destructive"
          @click="deleteRoutine"
        >
          Eliminar
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>
