<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import type { Routine } from '@/types/extended';
import type { ExerciseExecution } from '@/types/exercise-execution';
import {
  subMonths,
  subWeeks,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  isAfter,
  isBefore,
  differenceInWeeks,
  format,
  parse,
  eachWeekOfInterval,
} from 'date-fns';
import { workoutsApi, exerciseExecutionsApi } from '@/api';
import { es } from 'date-fns/locale';

const props = defineProps<{
  routines: Routine[];
  workoutPlanId: number;
  traineeId: number;
}>();

const startDateString = ref(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
const endDateString = ref(format(endOfMonth(new Date()), 'yyyy-MM-dd'));

const startDate = computed(() => parse(startDateString.value, 'yyyy-MM-dd', new Date()));
const endDate = computed(() => parse(endDateString.value, 'yyyy-MM-dd', new Date()));

const workoutsQueryParams = ref({
  'routine_workout_plan_id_eq': props.workoutPlanId,
  s: 'ended_at asc',
});

const { data: workoutsResponse } = useQuery({
  queryKey: ['workouts', workoutsQueryParams],
  queryFn: () => workoutsApi.getAll({
    filters: workoutsQueryParams.value,
  }),
  initialData: [],
});

const workouts = computed(() => workoutsResponse.value || []);

const exerciseExecutions = ref<ExerciseExecution[]>([]);

onMounted(async () => {
  const response = await exerciseExecutionsApi.getAll({ traineeId: props.traineeId });
  exerciseExecutions.value = response;
});

const stats = computed(() => {
  const selectedRoutines = props.routines.filter(routine =>
    isAfter(new Date(routine.scheduledAt), startDate.value) &&
    isBefore(new Date(routine.scheduledAt), endDate.value),
  );
  const completedSelectedRoutines = selectedRoutines.filter(routine => routine.isCompleted);
  const selectedWorkouts = workouts.value.filter(workout =>
    workout.endedAt &&
    isAfter(new Date(workout.endedAt), startDate.value) &&
    isBefore(new Date(workout.endedAt), endDate.value),
  );

  const selectedExecutions = exerciseExecutions.value.filter(execution =>
    isAfter(new Date(execution.executionDate), startDate.value) &&
    isBefore(new Date(execution.executionDate), endDate.value),
  );

  const totalReps = selectedExecutions.reduce((sum, execution) => sum + (execution.totalRepetitions || 0), 0);
  const totalVolume = selectedExecutions.reduce((sum, execution) => sum + (execution.totalVolume || 0), 0);

  const weeksInRange = differenceInWeeks(endDate.value, startDate.value) + 1;

  return {
    selectedRange: {
      completed: `${completedSelectedRoutines.length} / ${selectedRoutines.length}`,
      percentage: `${(completedSelectedRoutines.length / selectedRoutines.length * 100 || 0).toFixed(0)}%`,
      avgCompletedRoutines: (completedSelectedRoutines.length / weeksInRange).toFixed(1),
      avgWorkouts: (selectedWorkouts.length / weeksInRange).toFixed(1),
      totalWorkouts: selectedWorkouts.length,
      totalReps,
      totalVolume: totalVolume.toFixed(0),
    },
  };
});

const selectedStat = ref<keyof typeof stats.value.selectedRange | null>(null);

const chartData = computed(() => {
  if (!selectedStat.value) return null;

  const weeks = eachWeekOfInterval({ start: startDate.value, end: endDate.value }, { weekStartsOn: 1 });

  function getWeekData(weekStart: Date) {
    const weekEnd = endOfWeek(weekStart, { weekStartsOn: 1 });
    const weekRoutines = props.routines.filter(routine =>
      isAfter(new Date(routine.scheduledAt), weekStart) &&
      isBefore(new Date(routine.scheduledAt), weekEnd),
    );
    const weekWorkouts = workouts.value.filter(workout =>
      workout.endedAt &&
      isAfter(new Date(workout.endedAt), weekStart) &&
      isBefore(new Date(workout.endedAt), weekEnd),
    );
    const weekExecutions = exerciseExecutions.value.filter(execution =>
      isAfter(new Date(execution.executionDate), weekStart) &&
      isBefore(new Date(execution.executionDate), weekEnd),
    );

    const completedRoutinesCount = weekRoutines.filter(routine => routine.isCompleted).length;

    return {
      week: format(weekStart, 'dd MMM', { locale: es }),
      completedRoutines: completedRoutinesCount,
      totalRoutines: weekRoutines.length,
      percentageCompleted: weekRoutines.length ? ((completedRoutinesCount / weekRoutines.length) * 100).toFixed(0) : 0,
      workouts: weekWorkouts.length,
      totalReps: weekExecutions.reduce((sum, execution) => sum + (execution.totalRepetitions || 0), 0),
      totalVolume: weekExecutions.reduce((sum, execution) => sum + (execution.totalVolume || 0), 0).toFixed(0),
    };
  }

  const data = weeks.map(getWeekData);

  const seriesMap = {
    completed: 'Rutinas completadas',
    avgCompletedRoutines: 'Rutinas completadas por semana',
    percentage: 'Porcentaje completado',
    avgWorkouts: 'Workouts por semana',
    totalWorkouts: 'Total workouts',
    totalReps: 'Repeticiones totales',
    totalVolume: 'Volumen total',
  };

  // eslint-disable-next-line complexity
  function getDataValue(d: Record<string, unknown>, key: string): number {
    switch (key) {
    case 'completed':
    case 'avgCompletedRoutines':
      return Number(d.completedRoutines) || 0;
    case 'percentage':
      return Number(d.percentageCompleted) || 0;
    case 'avgWorkouts':
    case 'totalWorkouts':
      return Number(d.workouts) || 0;
    default:
      return Number(d[key]) || 0;
    }
  }

  const series = [{
    name: seriesMap[selectedStat.value as keyof typeof seriesMap] || '',
    data: data.map(d => getDataValue(d, selectedStat.value)),
  }];

  const yaxisTitle = seriesMap[selectedStat.value as keyof typeof seriesMap] || '';

  return {
    options: {
      chart: { type: 'bar' },
      xaxis: { categories: data.map(d => d.week) },
      yaxis: { title: { text: yaxisTitle } },
    },
    series,
  };
});

function selectStat(stat: keyof typeof stats.value.selectedRange) {
  selectedStat.value = selectedStat.value === stat ? null : stat;
}

function setDateRange(start: Date, end: Date) {
  startDateString.value = format(start, 'yyyy-MM-dd');
  endDateString.value = format(end, 'yyyy-MM-dd');
}

function setLastMonth() {
  const now = new Date();
  setDateRange(subMonths(now, 1), now);
}

function setLastWeek() {
  const now = new Date();
  setDateRange(startOfWeek(subWeeks(now, 1), { weekStartsOn: 1 }), endOfWeek(subWeeks(now, 1), { weekStartsOn: 1 }));
}

function setThisMonth() {
  const now = new Date();
  setDateRange(startOfMonth(now), endOfMonth(now));
}

function setThisWeek() {
  const now = new Date();
  setDateRange(startOfWeek(now, { weekStartsOn: 1 }), endOfWeek(now, { weekStartsOn: 1 }));
}

function setAllTime() {
  const now = new Date();
  const firstRoutineDate = new Date(Math.min(...props.routines.map(r => new Date(r.scheduledAt).getTime())));
  setDateRange(firstRoutineDate, now);
}
</script>

<template>
  <div class="flex flex-col gap-8">
    <div class="mb-4 flex flex-col items-center justify-between gap-4 sm:flex-row">
      <div class="flex flex-wrap gap-2">
        <button
          class="rounded border border-gray-300 px-2 py-1 text-sm text-gray-700 transition-colors hover:bg-gray-100"
          @click="setLastMonth"
        >
          Último mes
        </button>
        <button
          class="rounded border border-gray-300 px-2 py-1 text-sm text-gray-700 transition-colors hover:bg-gray-100"
          @click="setLastWeek"
        >
          Última semana
        </button>
        <button
          class="rounded border border-gray-300 px-2 py-1 text-sm text-gray-700 transition-colors hover:bg-gray-100"
          @click="setThisMonth"
        >
          Este mes
        </button>
        <button
          class="rounded border border-gray-300 px-2 py-1 text-sm text-gray-700 transition-colors hover:bg-gray-100"
          @click="setThisWeek"
        >
          Esta semana
        </button>
        <button
          class="rounded border border-gray-300 px-2 py-1 text-sm text-gray-700 transition-colors hover:bg-gray-100"
          @click="setAllTime"
        >
          Todo
        </button>
      </div>
      <div class="flex gap-2">
        <input
          v-model="startDateString"
          type="date"
          class="rounded border p-2"
        >
        <input
          v-model="endDateString"
          type="date"
          class="rounded border p-2"
        >
      </div>
    </div>

    <div class="rounded-lg bg-white p-6">
      <div class="mb-4 flex items-center gap-4">
        <span class="rounded-full bg-blue-100 px-3 py-1 text-xs font-semibold text-blue-800">
          {{ format(startDate, 'd MMM yyyy', { locale: es }) }} - {{ format(endDate, 'd MMM yyyy', { locale: es }) }}
        </span>
      </div>
      <h3 class="mb-4 text-xl font-semibold">
        Estadísticas
      </h3>
      <div class="grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-4">
        <div
          v-for="(value, key) in stats.selectedRange"
          :key="key"
          class="cursor-pointer rounded bg-gray-50 p-4 transition-colors hover:bg-gray-100"
          @click="selectStat(key)"
        >
          <p class="mb-1 text-sm text-gray-500">
            {{
              key === 'completed' ? 'Rutinas completadas' :
              key === 'percentage' ? 'Porcentaje completado' :
              key === 'avgCompletedRoutines' ? 'Promedio rutinas completadas/semana' :
              key === 'avgWorkouts' ? 'Promedio workouts/semana' :
              key === 'totalWorkouts' ? 'Total workouts' :
              key === 'totalReps' ? 'Total repeticiones' :
              'Volumen total'
            }}
          </p>
          <p class="text-2xl font-bold">
            {{ value }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="chartData"
      class="rounded-lg bg-white p-6"
    >
      <apexchart
        width="100%"
        height="350"
        type="bar"
        :options="chartData.options"
        :series="chartData.series"
      />
    </div>
  </div>
</template>
