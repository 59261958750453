<script setup lang="ts">
import { ref, computed } from 'vue';
import { AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/vue-query';
import { routineTemplatesApi, routinesApi } from '@/api';
import { useRoutinesStore } from '@/stores/routines';
import type { RoutineAttributes, RoutineTemplate } from '@/types';
import { BaseButton, BaseInput, BaseLabel, BaseModal } from '.';

const props = defineProps<{
  workoutPlanId: number,
  from: 'scratch' | 'template' | undefined,
}>();

const emit = defineEmits<{ close: [] }>();

const isOpen = computed(() => props.from !== undefined);

const routinesStore = useRoutinesStore();

const { data: routineTemplates } = useQuery({
  queryKey: ['routine_templates'],
  queryFn: () => routineTemplatesApi.getAll(),
  enabled: isOpen,
});

const {
  isError,
  isPending,
  isSuccess,
  error: createRoutineError,
  mutate: createRoutine,
  reset: resetCreateRoutine,
} = useMutation({
  mutationFn: (attributes: RoutineAttributes) => routinesApi.create(attributes),
  onSuccess: (newRoutine) => {
    routinesStore.setRoutine(newRoutine);
  },
});

const selectedRoutineTemplate = ref<RoutineTemplate | undefined>(undefined);

const emptyRoutineAttributes: RoutineAttributes = {
  name: 'Nueva rutina',
  scheduledAt: '',
  workoutPlanId: props.workoutPlanId,
  duration: 0,
  published: false,
};

const routineAttributes = ref(props.from === 'scratch' ? emptyRoutineAttributes : undefined);

function handleClose() {
  selectedRoutineTemplate.value = undefined;
  routineAttributes.value = undefined;
  resetCreateRoutine();
  emit('close');
}

function handleNext(routineTemplate?: RoutineTemplate) {
  if (routineTemplate === undefined) return;

  routineAttributes.value = {
    ...routineTemplate.routineAttributes,
    scheduledAt: '',
    workoutPlanId: props.workoutPlanId,
  };
}
</script>

<template>
  <BaseModal
    :open="isOpen"
    title="Agregar rutina"
    @close="handleClose"
  >
    <div
      v-if="isSuccess"
      class="flex flex-col gap-8"
    >
      <span>
        Rutina creada exitosamente
      </span>
      <BaseButton @click="handleClose">
        Cerrar
      </BaseButton>
    </div>
    <div
      v-else-if="isPending"
      class="flex flex-col gap-8"
    >
      Cargando...
    </div>
    <div
      v-else-if="isError"
      class="flex flex-col gap-8"
    >
      <span>
        Ocurrió un error al crear la rutina
      </span>
      <span
        v-if="(createRoutineError instanceof AxiosError)"
        class="whitespace-pre-wrap text-sm"
      >
        {{ createRoutineError.response?.data }}
      </span>
      <BaseButton @click="resetCreateRoutine">
        Reintentar
      </BaseButton>
    </div>
    <div
      v-else-if="routineAttributes !== undefined"
      class="flex flex-col gap-8"
    >
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Fecha"
          name="scheduledAt"
          required
        />
        <BaseInput
          v-model="routineAttributes.scheduledAt"
          name="scheduledAt"
          type="datetime-local"
        />
      </div>
      <div class="flex justify-end">
        <BaseButton
          variant="outline"
          @click="handleClose"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          v-if="routineAttributes !== undefined"
          :disabled="routineAttributes.scheduledAt === ''"
          @click="createRoutine(routineAttributes);"
        >
          Crear rutina
        </BaseButton>
      </div>
    </div>
    <div
      v-else
      class="grid grid-cols-2 gap-8"
    >
      <div class="flex h-[50dvh] flex-col gap-2 overflow-y-scroll">
        <h3 class="mb-2 font-semibold leading-none tracking-tight">
          Plantillas
        </h3>
        <button
          v-for="routineTemplate in routineTemplates"
          :key="routineTemplate.id"
          @click="selectedRoutineTemplate = routineTemplate"
        >
          <div
            class="flex w-full flex-col gap-2 rounded-lg border p-4 text-left text-sm font-medium hover:bg-gray-100"
            :class="{ 'bg-gray-100': selectedRoutineTemplate?.id === routineTemplate.id }"
          >
            {{ routineTemplate.name }}
          </div>
        </button>
      </div>
      <div class="h-[50dvh] overflow-y-scroll">
        <h3 class="mb-2 font-semibold leading-none tracking-tight">
          Detalle
        </h3>
        <p
          v-if="selectedRoutineTemplate"
          class="whitespace-pre-line py-2 text-xs text-gray-500"
        >
          {{ selectedRoutineTemplate.details }}
        </p>
        <p
          v-else
          class="py-2 text-xs text-gray-500"
        >
          Selecciona una plantilla para ver su detalle
        </p>
      </div>
      <div class="col-span-2 flex justify-end gap-2">
        <BaseButton
          variant="outline"
          @click="handleClose"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          :disabled="selectedRoutineTemplate === undefined"
          @click="handleNext(selectedRoutineTemplate)"
        >
          Siguiente
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>
