<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { routinesApi } from '@/api';
import type { RoutineAttributes } from '@/types';
import type { Routine, Workout } from '@/types/extended';
import { BaseModal, BaseButton } from '@/components';
import { useRoutinesStore } from '@/stores/routines';
import { AxiosError } from 'axios';
import { analytics } from '@/utils/analytics';
import WorkoutSummary from './workout-summary.vue';

const props = defineProps<{
  routine: Routine,
  isOpen: boolean,
}>();

const emit = defineEmits<{
  close: [isSuccess: boolean],
}>();

const {
  data: progressedRoutineAttributes,
  error: progressError,
  isIdle: isProgressIdle,
  isPending: isProgressPending,
  isError: isProgressError,
  isSuccess: isProgressSuccess,
  mutate: progressRoutine,
  reset: resetProgressRoutine,
} = useMutation({
  mutationFn: () => routinesApi.progress(props.routine),
  onMutate: () => {
    if (analytics) analytics.capture('progress_routine_click', { routineId: props.routine.id });
  },
});

const showWorkoutSummary = ref(false);

const routinesStore = useRoutinesStore();
const {
  error: updateError,
  isIdle: isUpdateIdle,
  isPending: isUpdatePending,
  isError: isUpdateError,
  isSuccess: isUpdateSuccess,
  mutate: updateRoutine,
  reset: resetUpdateRoutine,
} = useMutation({
  mutationFn: (routineAttributes: RoutineAttributes) => routinesApi.update(props.routine.id, routineAttributes),
  onMutate: () => {
    if (analytics) analytics.capture('save_progressed_routine_click', { routineId: props.routine.id });
  },
  onSuccess: (newRoutine) => {
    routinesStore.setRoutine(newRoutine);
  },
});

const {
  data: originalRoutine,
  isLoading: isLoadingOriginalRoutine,
} = useQuery({
  queryKey: ['originalRoutine', props.routine.originalId],
  queryFn: () => (props.routine.originalId ? routinesApi.get(props.routine.originalId) : null),
  enabled: () => props.isOpen && !!props.routine.originalId,
});

const lastCompletedWorkout = computed<Workout | null>(() => {
  if (!originalRoutine.value || !originalRoutine.value.workouts) return null;

  const completedWorkouts = originalRoutine.value.workouts
    .filter(workout => workout.endedAt)
    .sort((a, b) => new Date(b.endedAt!).getTime() - new Date(a.endedAt!).getTime());

  return completedWorkouts.length > 0 ? completedWorkouts[0] : null;
});

function toggleWorkoutSummary() {
  if (lastCompletedWorkout.value) {
    showWorkoutSummary.value = !showWorkoutSummary.value;
  }
}

function handleClose() {
  if (isUpdateSuccess.value) {
    resetProgressRoutine();
    resetUpdateRoutine();
    emit('close', true);
  } else {
    resetProgressRoutine();
    emit('close', false);
  }
}

const isEditMode = ref(false);
const editableRoutineAttributes = ref<RoutineAttributes | undefined>(undefined);

watch(progressedRoutineAttributes, (newValue) => {
  if (newValue) {
    editableRoutineAttributes.value = structuredClone(newValue);
  }
});

function toggleEditMode() {
  isEditMode.value = !isEditMode.value;
}

function findRoutineExerciseSet({
  routineAttributes,
  phaseId,
  exerciseId,
  setId,
}: {
  routineAttributes?: RoutineAttributes,
  phaseId: number,
  exerciseId: number,
  setId: number,
}) {
  if (!routineAttributes) return undefined;

  const phase = routineAttributes!.routinePhasesAttributes?.find((p) => p.id === phaseId);
  const exercise = phase?.routinePhaseExercisesAttributes?.find((e) => e.id === exerciseId);
  const set = exercise?.routineExerciseSetsAttributes?.find((s) => s.id === setId);

  return set;
}

const routineData = computed(() => {
  if (!editableRoutineAttributes.value) return [];

  return props.routine.routinePhases.flatMap((phase) =>
    phase.routinePhaseExercises.flatMap((exercise) =>
      exercise.routineExerciseSets.map((set) => {
        const progressedSet = findRoutineExerciseSet({
          routineAttributes: editableRoutineAttributes.value,
          phaseId: phase.id,
          exerciseId: exercise.id,
          setId: set.id,
        });

        return {
          phaseName: phase.name,
          exerciseName: exercise.exercise.name,
          setNumber: set.setNumber,
          repetitions: progressedSet?.repetitions ?? set.repetitions,
          weight: progressedSet?.weight ?? set.weight,
          phaseId: phase.id,
          exerciseId: exercise.id,
          setId: set.id,
          originalRepetitions: set.repetitions,
          originalWeight: set.weight,
        };
      }),
    ),
  );
});

function updateSet({
  phaseId,
  exerciseId,
  setId,
  field,
  value,
}: {
  phaseId: number,
  exerciseId: number,
  setId: number,
  field: 'repetitions' | 'weight',
  value: number,
}) {
  if (!editableRoutineAttributes.value) return;

  const set = findRoutineExerciseSet({
    routineAttributes: editableRoutineAttributes.value,
    phaseId,
    exerciseId,
    setId,
  });
  if (set) {
    set[field] = value;
  }
}

function handleUpdateRoutine() {
  if (!editableRoutineAttributes.value) return;

  updateRoutine(editableRoutineAttributes.value);
}

</script>

<template>
  <BaseModal
    :open="props.isOpen"
    :title="props.routine.name"
    @close="handleClose"
  >
    <div
      v-if="lastCompletedWorkout && !isUpdateSuccess"
      class="flex justify-end"
    >
      <BaseButton
        size="sm"
        variant="secondary"
        @click="toggleWorkoutSummary"
      >
        📊 Ver entrenamiento realizado
      </BaseButton>
      <WorkoutSummary
        v-if="originalRoutine && showWorkoutSummary"
        :routine="originalRoutine"
        :workout="lastCompletedWorkout"
        @close="showWorkoutSummary = false"
      />
    </div>
    <div
      v-if="isProgressIdle"
      class="flex flex-col gap-4"
    >
      <div
        v-if="isLoadingOriginalRoutine"
        class="text-center"
      >
        Cargando último entrenamiento...
      </div>

      <div
        v-else-if="lastCompletedWorkout"
        class="my-4 flex flex-col gap-2"
      >
        <p>
          Se generará una progresión de la rutina actual en base al último entrenamiento realizado.
        </p>
        <p>
          Podrás revisar y editar las progresiones antes de guardar la rutina.
        </p>
      </div>

      <div
        v-else-if="!isLoadingOriginalRoutine"
        class="text-red-600"
      >
        No se encontraron entrenamientos completados para la rutina original.
      </div>

      <BaseButton
        :disabled="!lastCompletedWorkout"
        @click="progressRoutine"
      >
        Progresar rutina
      </BaseButton>
    </div>
    <div
      v-else-if="isProgressPending"
      class="flex justify-center"
    >
      Progresando rutina...
    </div>
    <div
      v-else-if="isProgressError"
      class="flex flex-col justify-center gap-4"
    >
      <p>Error al progresar la rutina:</p>
      <p v-if="(progressError instanceof AxiosError)">
        {{ progressError.response?.data ?? progressError.message }}
      </p>
      <BaseButton
        @click="() => resetProgressRoutine()"
      >
        Volver a intentar
      </BaseButton>
    </div>
    <div
      v-else-if="isProgressSuccess"
      class="flex flex-col justify-center gap-8"
    >
      <div
        v-if="isUpdateIdle"
        class="flex flex-col justify-center gap-8"
      >
        <table class="w-full border-collapse text-xs">
          <thead>
            <tr>
              <th class="border px-2 py-1 text-left">
                Fase
              </th>
              <th class="border px-2 py-1 text-left">
                Ejercicio
              </th>
              <th class="border px-2 py-1 text-left">
                Serie
              </th>
              <th class="border px-2 py-1 text-left">
                Reps
              </th>
              <th class="border px-2 py-1 text-left">
                Peso
              </th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="(item, index) in routineData"
              :key="item.setId"
            >
              <tr>
                <td
                  v-if="index === 0 || routineData[index - 1].phaseId !== item.phaseId"
                  class="border px-2 py-1"
                  :rowspan="routineData.filter(i => i.phaseId === item.phaseId).length"
                >
                  {{ item.phaseName }}
                </td>
                <td
                  v-if="index === 0 || routineData[index - 1].exerciseId !== item.exerciseId"
                  class="border px-2 py-1"
                  :rowspan="routineData.filter(i => i.exerciseId === item.exerciseId).length"
                >
                  {{ item.exerciseName }}
                </td>
                <td class="border px-2 py-1 text-gray-400">
                  {{ item.setNumber }}
                </td>
                <td class="border px-2 py-1">
                  <input
                    v-if="isEditMode"
                    :value="item.repetitions"
                    type="number"
                    @input="updateSet({
                      phaseId: item.phaseId,
                      exerciseId: item.exerciseId,
                      setId: item.setId,
                      field: 'repetitions',
                      value: Number(($event.target as HTMLInputElement).value)
                    })"
                  >
                  <span v-else>
                    {{ item.repetitions }}
                    <span
                      v-if="item.originalRepetitions != null && item.repetitions != null &&
                        item.repetitions !== item.originalRepetitions"
                      class="text-gray-400"
                    >
                      ({{ item.repetitions > item.originalRepetitions ? '+' : '' }}{{
                        item.repetitions - item.originalRepetitions }})
                    </span>
                  </span>
                </td>
                <td class="border px-2 py-1">
                  <input
                    v-if="isEditMode"
                    :value="item.weight"
                    type="number"
                    step="0.5"
                    @input="updateSet({
                      phaseId: item.phaseId,
                      exerciseId: item.exerciseId,
                      setId: item.setId,
                      field: 'weight',
                      value: Number(($event.target as HTMLInputElement).value)
                    })"
                  >
                  <span v-else>
                    {{ item.weight }}
                    <span
                      v-if="item.originalWeight != null && item.weight != null &&
                        item.weight !== item.originalWeight"
                      class="text-gray-400"
                    >
                      ({{ item.weight > item.originalWeight ? '+' : '' }}{{
                        (item.weight - item.originalWeight).toFixed(1) }})
                    </span>
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <div class="flex justify-end gap-4">
          <BaseButton
            variant="secondary"
            @click="handleClose"
          >
            Cancelar
          </BaseButton>
          <BaseButton
            variant="secondary"
            @click="toggleEditMode"
          >
            <span class="flex items-center">
              {{ isEditMode ? 'Dejar de editar' : 'Editar progresiones' }}
            </span>
          </BaseButton>
          <BaseButton
            @click="handleUpdateRoutine"
          >
            Guardar
          </BaseButton>
        </div>
      </div>
      <div
        v-else-if="isUpdatePending"
        class="flex justify-center"
      >
        Guardando rutina...
      </div>
      <div
        v-else-if="isUpdateError"
        class="flex flex-col justify-center gap-2"
      >
        <p>Error al guardar la rutina</p>
        <p>{{ updateError?.message }}</p>
        <BaseButton
          @click="() => resetUpdateRoutine()"
        >
          Volver a intentar
        </BaseButton>
      </div>
      <div
        v-else-if="isUpdateSuccess"
        class="flex flex-col justify-center gap-8"
      >
        <p>
          Rutina guardada con éxito 🎉
        </p>
        <BaseButton
          @click="handleClose"
        >
          Cerrar
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>
