<script setup lang="ts">
import { ref, toRefs } from 'vue';
import { Form } from 'vee-validate';
import { array, number, object, string } from 'yup';
import { toTypedSchema } from '@vee-validate/yup';
import { api } from '@/api/axios-wrapper';
import { muscleGroupsApi, equipmentsApi, routinesApi } from '@/api';
import { useQuery, useMutation } from '@tanstack/vue-query';
import type { RoutineAttributes } from '@/types';
import { BaseModal, BaseButton, BaseInput, BaseLabel, BaseMultiselect } from '@/components';
import { useRoutinesStore } from '@/stores/routines';
import { AxiosError } from 'axios';

const ROUTINE_PROPOSAL_PLACEHOLDER = `Ejemplo:
Pectoral, Isquiotibial, Tríceps
Warm Up: Calentamiento en bicicleta 5 minutos
Activación: 2 series Push Up
Fuerza 1: 3 series Press banca con mancuernas
Fuerza 2: 3 series leg curl sentado
Fuerza 3: 3 series Máquina chest fly
Fuerza 4: 3 series Estocada en reversa con mancuerna
Fuerza 5: 4 series Extensiones de triceps en poleas
`;

const props = defineProps<{
  workoutPlanId: number,
  isOpen: boolean,
}>();

const emit = defineEmits<{ close: [] }>();

const validationSchema = toTypedSchema(
  object({
    scheduledAt: string().required(),
    duration: number().positive().required(),
    objective: string().required(),
    muscleGroups: array().of(string()).min(1),
    availableEquipment: array().of(string()).min(1),
    routineProposal: string().required(),
  }));

const formValues = ref({
  scheduledAt: '',
  duration: 0,
  objective: '',
  muscleGroups: [] as string[],
  availableEquipment: ['Sin equipo'] as string[],
  routineProposal: '',
});

const { isOpen } = toRefs(props);

const { data: muscleGroups } = useQuery({
  queryKey: ['muscleGroups'],
  queryFn: () => muscleGroupsApi.getAll(),
  initialData: [],
  enabled: isOpen,
});

const { data: equipments } = useQuery({
  queryKey: ['equipments'],
  queryFn: () => equipmentsApi.getAll(),
  initialData: [],
  enabled: isOpen,
});

type GeneratedRoutine = {
  routineJson: RoutineAttributes,
  routineDetail: string,
};

async function requestRoutineGenerate(): Promise<GeneratedRoutine> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { scheduledAt, ...routineGenerateAttributes } = formValues.value;

  const response = await api({
    method: 'post',
    url: 'https://kapso-ai.fly.dev/api/routines/create',
    data: routineGenerateAttributes,
  });

  return response.data;
}

const {
  data: generatedRoutine,
  error: generateError,
  isIdle: isGenerateIdle,
  isPending: isGeneratePending,
  isError: isGenerateError,
  isSuccess: isGenerateSuccess,
  mutate: generateRoutine,
  reset: resetGenerateRoutine,
} = useMutation({
  mutationFn: requestRoutineGenerate,
});

const routinesStore = useRoutinesStore();
const {
  error: createError,
  isIdle: isCreateIdle,
  isPending: isCreatePending,
  isError: isCreateError,
  isSuccess: isCreateSuccess,
  mutate: createRoutine,
  reset: resetCreateRoutine,
} = useMutation({
  mutationFn: () => {
    if (generatedRoutine.value === undefined) return Promise.reject();

    const routineAttributes = {
      ...generatedRoutine.value.routineJson,
      workoutPlanId: props.workoutPlanId,
      scheduledAt: formValues.value.scheduledAt,
    } as RoutineAttributes;

    return routinesApi.create(routineAttributes);
  },
  onSuccess: (newRoutine) => {
    routinesStore.setRoutine(newRoutine);
  },
});
</script>

<template>
  <BaseModal
    :open="props.isOpen"
    title="Generador de rutinas"
    @close="() => emit('close')"
  >
    <Form
      v-if="isGenerateIdle"
      class="flex flex-col gap-4"
      :validation-schema="validationSchema"
      @submit="() => generateRoutine()"
    >
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Fecha"
          name="scheduledAt"
          required
        />
        <BaseInput
          v-model="formValues.scheduledAt"
          name="scheduledAt"
          type="datetime-local"
        />
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Duración"
          name="duration"
          required
        />
        <BaseInput
          v-model="formValues.duration"
          name="duration"
          type="number"
          placeholder="ej. 30 minutos"
        />
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Objetivo"
          name="objective"
          required
        />
        <BaseInput
          is="textarea"
          id="objective"
          v-model="formValues.objective"
          name="objective"
          type="text"
        />
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Grupos musculares"
          name="muscleGroups"
          required
        />
        <BaseMultiselect
          v-if="formValues.muscleGroups"
          :model-value="formValues.muscleGroups"
          name="muscleGroups"
          :options="muscleGroups"
          by="name"
          @update:model-value="formValues.muscleGroups = $event"
        />
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Equipamiento"
          name="availableEquipment"
        />
        <BaseMultiselect
          v-if="formValues.availableEquipment"
          :model-value="formValues.availableEquipment"
          name="availableEquipment"
          :options="equipments"
          by="name"
          @update:model-value="formValues.availableEquipment = $event"
        />
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Propuesta de rutina"
          name="routineProposal"
          required
        />
        <BaseInput
          is="textarea"
          v-model="formValues.routineProposal"
          name="routineProposal"
          type="text"
          :placeholder="ROUTINE_PROPOSAL_PLACEHOLDER"
        />
      </div>
      <BaseButton type="submit">
        Generar rutina
      </BaseButton>
    </Form>
    <div
      v-else-if="isGeneratePending"
      class="flex justify-center"
    >
      Generando rutina... Esto puede tardar unos minutos
    </div>
    <div
      v-else-if="isGenerateError"
      class="flex flex-col justify-center gap-2"
    >
      <p>Error al generar la rutina:</p>
      <p v-if="(generateError instanceof AxiosError)">
        {{ generateError.response?.data }}
      </p>
      <BaseButton
        @click="() => resetGenerateRoutine()"
      >
        Volver a intentar
      </BaseButton>
    </div>
    <div
      v-else-if="isGenerateSuccess"
      class="flex flex-col justify-center gap-2"
    >
      <p class="whitespace-pre-line text-xs">
        {{ generatedRoutine?.routineDetail }}
      </p>
      <BaseButton
        v-if="isCreateIdle"
        @click="() => createRoutine()"
      >
        Crear rutina
      </BaseButton>
      <div
        v-else-if="isCreatePending"
        class="flex justify-center"
      >
        Creando rutina...
      </div>
      <div
        v-else-if="isCreateError"
        class="flex flex-col justify-center gap-2"
      >
        <p>Error al crear la rutina</p>
        <p>{{ createError?.message }}</p>
        <BaseButton
          @click="() => resetCreateRoutine()"
        >
          Volver a intentar
        </BaseButton>
      </div>
      <div
        v-else-if="isCreateSuccess"
        class="flex flex-col justify-center gap-2"
      >
        <p>
          Rutina creada con éxito
        </p>
        <BaseButton
          @click="() => emit('close')"
        >
          Cerrar
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>
